// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jtRlcmsNT"];

const serializationHash = "framer-duoX5"

const variantClassNames = {jtRlcmsNT: "framer-v-2s0zx6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, ZCsLXUICt: title ?? props.ZCsLXUICt ?? "Tag"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZCsLXUICt, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jtRlcmsNT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-duoX5", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-2s0zx6", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jtRlcmsNT"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(216, 223, 242)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(245, 247, 255)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy1yZWd1bGFy", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-line-height": "16px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(26, 29, 35))"}}>Tag</motion.p></React.Fragment>} className={"framer-123sswl"} data-framer-name={"Tag"} fonts={["GF;Poppins-regular"]} layoutDependency={layoutDependency} layoutId={"nhwvbuNEd"} style={{"--extracted-r6o4lv": "rgb(26, 29, 35)", "--framer-paragraph-spacing": "0px"}} text={ZCsLXUICt} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-duoX5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-duoX5 .framer-1lo02fc { display: block; }", ".framer-duoX5 .framer-2s0zx6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: hidden; padding: 4px 8px 4px 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-duoX5 .framer-123sswl { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-duoX5 .framer-2s0zx6 { gap: 0px; } .framer-duoX5 .framer-2s0zx6 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-duoX5 .framer-2s0zx6 > :first-child { margin-left: 0px; } .framer-duoX5 .framer-2s0zx6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 37
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"ZCsLXUICt":"title"}
 * @framerImmutableVariables true
 */
const FramermAaUKzyP0: React.ComponentType<Props> = withCSS(Component, css, "framer-duoX5") as typeof Component;
export default FramermAaUKzyP0;

FramermAaUKzyP0.displayName = "Tag Copy";

FramermAaUKzyP0.defaultProps = {height: 24, width: 37};

addPropertyControls(FramermAaUKzyP0, {ZCsLXUICt: {defaultValue: "Tag", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramermAaUKzyP0, [{family: "Poppins", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJXUc1NECPY.woff2", weight: "400"}])